export interface ProjectThingBudget {
  price: string
  priceCents: number
  quantity: number
  shipping?: string
  shippingCents?: number
  total: string
  totalCents: number
  tax?: string
  taxCents?: number
  taxPercentage?: number
  markup?: string
  markupCents?: number
  markupPercentage?: number
  discount?: string
  discountCents?: number
  discountPercentage?: number
  orderNumber?: string
  trackingInfo?: string
  leadTime?: string
  estimatedDeliveryAt?: string
  status: ProjectThingBudgetStatusChange
  createdAt: string
  updatedAt: string
  id: string
  /**
   * @deprecated This property is obsolete. Use `status` instead.
   */
  statusChanges: ProjectThingBudgetStatusChange[]
}

export type ProjectThingBudgetStatusChange = {
  status: ProjectThingBudgetStatus
  designer?: StatusDesignerType
  client?: StatusClientType
  createdAt: string
  id: string
}

type StatusDesignerType = {
  id: string
  firstName: string
  lastName: string
  imageUrl?: string
}

type StatusClientType = {
  id: string
  firstName: string
  lastName: string
}

export type ProjectThingBudgetStatus =
  | 'COMPLETED'
  | 'DELIVERED'
  | 'SHIPPED'
  | 'ORDERED'
  | 'LATER'
  | 'APPROVED'
  | 'SOURCE'

export const projectThingBudgetStatuses: ProjectThingBudgetStatus[] = [
  'APPROVED',
  'ORDERED',
  'LATER',
  'SHIPPED',
  'DELIVERED',
  'COMPLETED',
  'SOURCE',
]

export type ProjectBudget = {
  id: string
  amount: string
  amountCents: number
  suggestedAmount: string | null
  suggestedAmountCents: number | null
  status: ProjectBudgetStatus
}

export type ProjectBudgetStatus = 'DRAFT' | 'APPROVED' | 'REJECTED' | 'PENDING'

export type ProjectBudgetInput = {
  amountCents: number
  suggestedAmountCents?: number | null
  status: ProjectBudgetStatus
}

export type SectionBudget = {
  id: string
  amount: string
  amountCents: number
}

export type SectionBudgetInput = {
  amountCents: number
}

export type ProjectThingBudgetInput = {
  priceCents: number
  quantity: number
  shippingCents?: number
  taxCents?: number
  taxPercentage?: number
  markupCents?: number
  markupPercentage?: number
  discountCents?: number
  discountPercentage?: number
  orderNumber?: string
  trackingInfo?: string
  leadTime?: string
  estimatedDeliveryAt?: string
}

export type ProjectThingBudgetPriceInput = {
  priceCents: number
}

export type ProjectThingBudgetQuantityInput = {
  quantity: number
}

export type ProjectThingBudgetStatusChangeInput = {
  status: ProjectThingBudgetStatus
}

import type { ProjectAccess } from '../projects/types'
import type { Task } from '../school/types'
import type { MockupAccess } from '../things/types'
import type { DailyPlay } from '/@/common/daily-play/types'
import type { ProjectType } from '/@/common/projects/types'
import type { Room, RoomType } from '/@/common/rooms/types'
import type {
  DesignChallenge,
  DesignChallengeSubmission,
} from '/@/common/designChallenges/types'
import type { MockupTemplate } from '../templates/types'

export type MockupType = 'MOCKUP' | 'FLOOR_PLAN' | 'EDITORIAL' | 'ELEVATION'
export type ViziLimitType = 'MAGIC_ERASE' | 'ASSET'
export function isMockupType(value?: string): value is MockupType {
  if (!value) return false
  return ['MOCKUP', 'FLOOR_PLAN', 'EDITORIAL', 'ELEVATION'].includes(value)
}

export type SetupState =
  | 'IDLE'
  | 'REQUIRED'
  | 'COMPLETE'
  | 'TEMPLATE_SELECTION'
  | 'TEMPLATE_DETAILS'
  | 'CUSTOM_UPLOAD'
  | 'CUSTOM_UPLOAD_DETAILS'
  | 'CUSTOM_TEMPLATE_DETAILS'
  | 'THINGS_REPLACEMENT'

export type ImageScaleType =
  | 'PX_150'
  | 'PX_300'
  | 'PX_600'
  | 'PX_1200'
  | 'PX_2400'

export interface ScaledImage {
  imageScaleType: ImageScaleType
  imageUrl: string
}

export interface ScaledImages {
  PX_150: ScaledImage
  PX_300: ScaledImage
  PX_600: ScaledImage
  PX_1200: ScaledImage
  PX_2400: ScaledImage
}

export type MockupLimit = {
  id: string
  mockupId: string
  mockupLimitType: ViziLimitType
  current: number
  limit: number
}

export interface ViziVersion {
  id: string
  /**
   * @deprecated data has been replaced by dataRaw
   */
  data?: string

  dataRaw?: string
  dataUrl?: string
  imageUrl: string
  createdAt: string
  mockupId: string
  designerId: string
}

export interface Vizi {
  id: string
  name: string
  note?: string
  mockupType: MockupType
  isTemplate: boolean
  setupState?: SetupState

  /**
   * @deprecated data has been replaced by dataRaw
   */
  data?: string

  dataRaw?: string
  dataUrl?: string
  imageUrl: string
  costVisibility: 'ON' | 'OFF'
  project?: {
    id: string
    name: string
    visibility: 'PUBLIC' | 'PRIVATE'
    access: ProjectAccess
    projectType: ProjectType
  }
  section?: {
    id: string
    name: string
  }
  access: MockupAccess
  designerId?: string
  designer?: {
    id: string
    nickname: string
    firstName: string
  }
  dailyPlay?: DailyPlay
  task?: Task
  defaultBackgroundImageUrl?: string
  template?: {
    id: string
  }
  mockupTemplate?: MockupTemplate
  designChallenge?: DesignChallenge
  designChallengeSubmission?: DesignChallengeSubmission
  versions?: ViziVersion[]
  latestVersion?: ViziVersion
  vizVersion?: string
  isEdited: boolean
  limits: MockupLimit[]
  roomType?: RoomType
  roomTypeExtra?: string
  room?: Room
  scaledImages?: {
    PX_600: {
      imageUrl: string
    }
  }
}

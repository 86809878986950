import {
  type ComputedRef,
  type CSSProperties,
  type ComputedGetter,
  type WritableComputedOptions,
  type WritableComputedRef,
  type ComponentInternalInstance,
  computed,
  reactive,
  ref,
  toRaw,
  getCurrentInstance,
  onBeforeUnmount,
} from 'vue'
import { useConfig } from '/@/core/config'
import { useRoute, useRouter } from 'vue-router'
import { useUser } from '/@/data/user'
import { uid } from 'uid/single'
import type { MockupType } from '../common/things/types'
import type { StellaChecklistItem } from '/@/stella/StellaChecklist.vue'

// eslint-disable-next-line
export function debounce<T extends Function>(
  fn: T,
  time: number,
  { immediate = false }: { immediate?: boolean } = {},
) {
  let timeout: ReturnType<typeof setTimeout> | null

  return ((...args: unknown[]) => {
    const later = () => {
      timeout = null
      if (!immediate) fn(...args)
    }
    const callNow = immediate && !timeout
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(later, time)
    if (callNow) fn(...args)
  }) as unknown as T
}

const underscoreRegex = /_/g
export function toSentenceCase(str: string) {
  if (!str) return ''
  const [first, ...rest] = str.toLowerCase().replace(underscoreRegex, ' ')
  return [first.toUpperCase(), ...rest].join('')
}

export function toTitleCase(str: string) {
  if (!str) return ''
  const words = str.split(/[_\s]/)
  return words
    .map(word => {
      return toSentenceCase(word)
    })
    .join(' ')
}

export function pluralize(str: string, count: number) {
  if (count === 1) {
    return str
  } else {
    return `${str}s`
  }
}

export function tokenize(str: string) {
  if (!str) return ''
  const notAlphaNumRegex = /[^a-z0-9]/gi
  return str.toLowerCase().replace(notAlphaNumRegex, '-')
}

export function isDescendantOf(
  element: HTMLElement,
  cls: string,
  { includeSelf = false } = {},
) {
  if (includeSelf && element.classList.contains(cls)) return true

  let parent = element.parentElement
  while (parent) {
    if (parent.classList.contains(cls)) return true
    parent = parent.parentElement
  }
  return false
}

export async function imageUrlToDataUrl(url: string) {
  // need to transform a plain url to a data uri
  const blob = await fetch(url).then(r => r.blob())
  return await new Promise<string>(resolve => {
    const reader = new FileReader()
    reader.onload = () =>
      typeof reader.result === 'string' ? resolve(reader.result) : ''
    reader.readAsDataURL(blob)
  })
}

export async function downloadFile(dataUrl: string, name = 'download') {
  if (!dataUrl.startsWith('data:')) {
    // need to transform a plain url to a data uri
    dataUrl = await imageUrlToDataUrl(dataUrl)
  }

  // Create a new anchor element
  const a = document.createElement('a')

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = dataUrl
  a.download = name
  document.body.appendChild(a)

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click()

  document.body.removeChild(a)
}

export function dataUrlToFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1] || ''
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export async function imageUrlToFile(url: string) {
  const dataUrl = await imageUrlToDataUrl(url)
  return dataUrlToFile(dataUrl, url)
}

export function isValidImageType(file: File | null) {
  if (!file) return false
  return file.type.startsWith('image/')
}

export function useFullUrl(url?: string) {
  const config = useConfig()
  const route = useRoute()
  return computed(() => `${config.site}${url || route.fullPath}`)
}

export function hasSameUrlBase(a: string, b: string) {
  return a.split('?')[0] === b.split('?')[0]
}

export function useUid() {
  return `uid-${uid(12)}`
}

export function expensiveClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

export function sleep<T>(time: number = 0) {
  return new Promise<T>(resolve => setTimeout(resolve, time))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createStyle(object: any) {
  return object as CSSProperties
}

export function partition<T>(array: T[], condition: (t: T) => boolean) {
  const ret: [passed: T[], rest: T[]] = [[], []]
  for (const item of array) {
    if (condition(item)) {
      ret[0].push(item)
      continue
    }
    ret[1].push(item)
  }
  return ret
}
export function groupBy<K extends Extract<keyof T, string>, T>(
  array: T[],
  property: K,
) {
  const ret: Array<[key: T[K], value: T[]]> = [] as unknown as Array<
    [key: T[K], value: T[]]
  >
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const indeces = [] as any[]
  for (const item of array) {
    const key = item[property]
    let idx = indeces.indexOf(key)
    if (idx === -1) {
      ret.push([key, []])
      indeces.push(key)
      idx = indeces.length - 1
    }
    ret[idx][1].push(item)
  }
  return ret
}

export function equalArrays<T>(
  array1?: T[] | null,
  array2?: T[] | null,
  comparison: (a: T, b: T) => boolean = (a: T, b: T) => a === b,
): boolean {
  if (!array1 || !array2) return false
  if (array1.length !== array2.length) return false
  for (let i = 0; i < array1.length; i++) {
    if (!comparison(array1[i], array2[i])) return false
  }
  return true
}

export function shuffleArray<T>(arr: T[]) {
  const array = [...arr]
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export function equalSets<T>(as: Set<T>, bs: Set<T>) {
  if (as.size !== bs.size) {
    return false
  }
  for (const a of as) {
    if (!bs.has(a)) {
      return false
    }
  }
  return true
}

export type ViewerType =
  | 'anonymous'
  | 'designer'
  | 'owner'
  | 'client'
  | 'collaborator'
export function useViewer({
  owner,
}: {
  owner: ComputedRef<{ id?: string } | undefined | null>
}) {
  const user = useUser()
  return computed<ViewerType>(() => {
    if (owner.value?.id && owner.value.id === user.current?.designerId) {
      return 'owner'
    }
    if (user.current?.designerId) return 'designer'
    return 'anonymous'
  })
}

export function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function cbcopy(text: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text)
  } else {
    return false
  }
}

export function useToggle(bool?: boolean) {
  const state = ref(Boolean(bool))
  const wasOnAtLeastOnce = ref(Boolean(bool))
  function on() {
    state.value = true
    wasOnAtLeastOnce.value = true
  }
  function off() {
    state.value = false
  }
  function toggle(bool: boolean = !state.value) {
    state.value = bool
    if (bool) wasOnAtLeastOnce.value = true
  }
  return reactive({
    state,
    on,
    off,
    toggle,
    wasOnAtLeastOnce,
  })
}

export function useRouteBasedTabs<T extends string = string>(options: T[]) {
  const route = useRoute()
  const router = useRouter()

  function set(tab: T) {
    router.push({ query: { ...route.query, t: tab } })
  }

  const current = computed({
    get: () => route.query.t as unknown as T,
    set: t => set(t),
  })

  return reactive({
    current,
    options,
    set,
  })
}

export function isValidHttpUrl(urlString: string | null | undefined) {
  if (!urlString) return false
  try {
    const url = new URL(urlString)
    return url.protocol === 'https:' || url.protocol === 'http:'
  } catch {
    return false
  }
}

const imageRegex =
  /\.apng|\.avif|\.gif|\.jpg|\.jpeg|\.jfif|\.pjpeg|\.pjp|\.png|\.svg|\.webp/i
export function hasImageExtension(val: string | null | undefined) {
  if (!val) return false
  return imageRegex.test(val)
}

export function truncateUrl(url: string) {
  return url.substring(0, 50)
}

export function getUrlDomain(urlString: string) {
  try {
    const url = new URL(urlString)
    return url.host
  } catch {
    return urlString
  }
}
export function makeInputHandler(
  type: 'string',
  fn: (value: string, validity: HTMLInputElement['validity']) => void,
): (event: Event) => void
export function makeInputHandler(
  type: 'number',
  fn: (value: number, validity: HTMLInputElement['validity']) => void,
): (event: Event) => void
export function makeInputHandler(
  type: 'date',
  fn: (value: Date | null, validity: HTMLInputElement['validity']) => void,
): (event: Event) => void
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeInputHandler(type: any, fn: any) {
  return (event: Event) => {
    const target = event.target as HTMLInputElement | null
    if (target) {
      if (type === 'string') fn(target.value, target.validity)
      if (type === 'number') fn(target.valueAsNumber, target.validity)
      if (type === 'date') fn(target.valueAsDate, target.validity)
    }
  }
}

export function truncateDecimals(value: string, numPlaces: number) {
  const pattern = `(\\d*?\\.\\d{${numPlaces}})\\d*`
  const regex = new RegExp(pattern)
  return parseFloat(value.replace(regex, '$1'))
}

export const isMacos = navigator.platform.startsWith('Mac')
export const metaModifierKey = isMacos ? 'metaKey' : 'ctrlKey'
export const metaKeyboardKey = isMacos ? 'Meta' : 'Control'

export function refreshableComputed<T>(
  getter: ComputedGetter<T>,
): ComputedRef<T> & { refresh: () => void }
export function refreshableComputed<T>(
  options: WritableComputedOptions<T>,
): WritableComputedRef<T> & { refresh: () => void }
export function refreshableComputed<T>(
  getterOrOptions: ComputedGetter<T> | WritableComputedOptions<T>,
):
  | (ComputedRef<T> & { refresh: () => void })
  | (WritableComputedRef<T> & { refresh: () => void }) {
  const isGetter = getterOrOptions instanceof Function
  const getter = isGetter ? getterOrOptions : getterOrOptions.get
  const setter = isGetter
    ? () => {
        // noop
      }
    : getterOrOptions.set

  const trigger = ref(1)
  const refreshable = computed<T>({
    get: () => {
      trigger.value
      return getter()
    },
    set: setter,
  })

  // @ts-ignore
  // eslint-disable-next-line vue/no-ref-as-operand
  refreshable.refresh = () => (trigger.value = trigger.value * -1)
  return refreshable as
    | (ComputedRef<T> & { refresh: () => void })
    | (WritableComputedRef<T> & { refresh: () => void })
}

type VendorParamsKey = `c_${string}`
interface VendorParams {
  [key: VendorParamsKey]: string | undefined
  c_id?: string
  c_product_id?: string
  c_room_type?: string
}
export function getVendorParams() {
  const query = new URL(window.location.href).searchParams
  const params = computed(() => {
    return Object.fromEntries(
      [...query.entries()].filter(([k]) => k.startsWith('c_')),
    ) as VendorParams
  })
  return params
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toQueryString(object: any) {
  return Object.entries(object)
    .map(([k, v]) => `${k}=${v}`)
    .join('&')
}

export function valueToPercentage(from: number, to: number, value: number) {
  const range = to - from
  return Math.round(((value - from) * 100) / range)
}

export function percentageToValue(
  from: number,
  to: number,
  percentage: number,
) {
  const range = to - from
  return (percentage * range) / 100 + from
}

export function range(from: number, to?: number) {
  if (to == null) {
    return [...Array(from).keys()]
  }

  return [...Array(to - from).keys()].map(n => n + from)
}

const hasOwn = Object.prototype.hasOwnProperty

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEqual(a: any, b: any) {
  let ctor, len
  if (a === b) return true

  if (a && b && (ctor = toRaw(a).constructor) === toRaw(b).constructor) {
    if (ctor === Date) return a.getTime() === b.getTime()
    if (ctor === RegExp) return a.toString() === b.toString()

    if (ctor === Array) {
      if ((len = a.length) === b.length) {
        while (len-- && deepEqual(a[len], b[len]));
      }
      return len === -1
    }

    if (!ctor || typeof a === 'object') {
      len = 0
      for (ctor in a) {
        if (hasOwn.call(a, ctor) && ++len && !hasOwn.call(b, ctor)) return false
        if (!(ctor in b) || !deepEqual(a[ctor], b[ctor])) return false
      }
      return Object.keys(b).length === len
    }
  }

  return a !== a && b !== b
}

/** Takes a Generator function and returns an async function that can be canceled if called again */
export function makeAutoCancelable(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generator: (...args: any[]) => IterableIterator<any>,
) {
  let globalNonce
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async function (...args: any[]) {
    const localNonce = (globalNonce = new Object())

    const iter = generator(...args)
    let resumeValue
    for (;;) {
      const n = iter.next(resumeValue)
      if (n.done) {
        return n.value // final return value of passed generator
      }

      // whatever the generator yielded, _now_ run await on it
      resumeValue = await n.value
      if (localNonce !== globalNonce) {
        return // a new call was made
      }
      // next loop, we give resumeValue back to the generator
    }
  }
}

interface SequenceValue<T> {
  value: T
  first: boolean
  last: boolean
}
export class Sequence<T> {
  private items: T[]
  private sequence: IterableIterator<SequenceValue<T>>
  current: SequenceValue<T>
  private generator = function* (
    this: Sequence<T>,
  ): Generator<SequenceValue<T>> {
    let i = 0
    while (true) {
      const len = this.items.length
      const dir = yield {
        value: this.items[i],
        first: i === 0,
        last: i === len - 1,
      }
      i += dir === 'fwd' ? 1 : -1
      if (i < 0) i = 0
      if (i >= len) i = len - 1
    }
  }
  constructor(items: T[]) {
    this.items = items
    this.sequence = this.generator.bind(this)()
    this.current = {
      first: true,
      last: false,
      value: this.items[0],
    }
  }
  *[Symbol.iterator]() {
    for (const item of this.items) yield item
  }
  next(): SequenceValue<T> {
    // @ts-ignore
    this.current = this.sequence.next('fwd').value
    return this.current
  }
  prev(): SequenceValue<T> {
    // @ts-ignore
    this.current = this.sequence.next('bwd').value
    return this.current
  }
  remove(item: T) {
    if (this.current.value !== item) {
      const removedIdx = this.items.indexOf(item)
      const currentIdx = this.items.indexOf(this.current.value)
      this.items.splice(removedIdx, 1)
      if (removedIdx < currentIdx) this.current = this.prev()
    }
    if (this.current.first) {
      this.next()
      this.items.splice(this.items.indexOf(item), 1)
      this.current = this.prev()
      return
    }
    this.current = this.prev()
    this.items.splice(this.items.indexOf(item), 1)
  }
  replace(oldItem: T, newItem: T) {
    this.items.splice(this.items.indexOf(oldItem), 1, newItem)
  }
}

export async function asyncWindowEvent(event: string) {
  return new Promise(resolve => {
    window.addEventListener(
      event,
      e => {
        resolve(e)
      },
      {
        once: true,
      },
    )
  })
}

export function mockupTypeToHumanString(type: MockupType) {
  switch (type) {
    case 'EDITORIAL':
      return 'Mood board'
    case 'FLOOR_PLAN':
      return 'Floor plan'
    case 'ELEVATION':
      return 'Elevation'
    case 'MOCKUP':
      return 'Room'
  }
}

// spaghetti
const listeners = new WeakMap<
  ComponentInternalInstance,
  Array<[evt: string, listener: (data?: unknown) => void]>
>()
export function useGlobalEvents() {
  const inst = getCurrentInstance()
  function fire(evt: string, payload?: unknown) {
    window.dispatchEvent(new CustomEvent(evt, { detail: payload }))
  }
  function on(evt: string, handler: (data?: unknown) => void) {
    if (!inst) return
    if (!listeners.has(inst)) listeners.set(inst, [])
    listeners.get(inst)!.push([evt, handler])
    window.addEventListener(evt, handler)
  }
  function off(evt?: string, handler?: (data?: unknown) => void) {
    if (!inst) return
    if (!listeners.has(inst)) return
    const ls = listeners.get(inst)!
    if (handler) {
      ls.splice(
        ls.findIndex(([, l]) => l === handler),
        1,
      )
      window.removeEventListener(evt!, handler)
      return
    }
    const toRemove = []
    for (const [e, l] of ls) {
      if (evt && e !== evt) continue
      toRemove.push(l)
      window.removeEventListener(e, l)
    }
    for (const handler of toRemove) {
      ls.splice(
        ls.findIndex(([, l]) => l === handler),
        1,
      )
    }
  }
  onBeforeUnmount(() => {
    off()
    if (inst) listeners.delete(inst)
  })
  return {
    fire,
    on,
    off,
  }
}

export function extractChecklistData<T>(
  data: T[],
  valueKey: keyof T,
  labelKey: keyof T = valueKey,
): StellaChecklistItem[] {
  const map = Object.create(null)
  for (const item of data) {
    map[item[valueKey]] = { value: item[valueKey], label: item[labelKey] }
  }
  return Object.values(map)
}

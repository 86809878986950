import { computed, reactive, readonly } from 'vue'
import {
  type ExternalFeatureId,
  type LocalFeatureId,
  localFeatureIds,
  isLocalFeature,
  isExternalFeature,
} from './types'
import { useExperiment } from '/@/core/experiment'

interface FeatureLaunch {
  launchDate: Date
}
const featureLaunches: Record<string, FeatureLaunch> = {
  budget: { launchDate: new Date('2022-07-12') },
  'manual-eraser': { launchDate: new Date('2022-08-08') },
  'ruler-feature': { launchDate: new Date('2022-07-09') },
  'viz-3': { launchDate: new Date('2023-03-01') },
  'magic-install': { launchDate: new Date('2023-04-24') },
  'smart-add': { launchDate: new Date('2023-05-15') },
}

export function featureLaunch(name: string): FeatureLaunch | undefined {
  return featureLaunches[name]
}

const state = reactive<Record<LocalFeatureId, boolean>>({
  'export video': false,
  'ss onboarding': true,
  'smartlook-canvas': false,
  // todo: when clean up time for this feature flag comes, search for "viz-options-artifacts" in the
  // code base, you will find a bunch of lines to be removed as part of the clean up.
  // (only if we're not relying on external feature flags, that is; in that case, keep the stuff)
  'viz-options': false,
  'magic-paint': false,
  'rich-color-filter': false,
  'mobile-viz': true,
  'fp-add-room': true,
  'professional-trial-14-days': true,
  'viz-transform-pipeline': true,
  'onboarding-pro-schedule': true,
  'super-pricing-model-v2': true,
  'homepage-new-2024': true,
  'template-assistant': true,
  'sourcing-assistant': true,
})

const sessionStorageKey = '__spoak-feature-map'

// rehydrate from sessionStorage
try {
  const cache = (JSON.parse(
    sessionStorage.getItem(sessionStorageKey) ?? 'null',
  ) ?? []) as Array<[id: LocalFeatureId, enabled: boolean]>
  for (const [id, bool] of cache) {
    if (!localFeatureIds.includes(id)) continue
    state[id] = bool
  }
} catch {
  // whatever
}

export function getLocalFeatureState() {
  return readonly(state)
}

export function useFeature(featureId: LocalFeatureId | ExternalFeatureId) {
  const localEnabled = computed(
    () => isLocalFeature(featureId) && state[featureId],
  )

  const shouldFetchExternalFlag = computed(() => {
    return !localEnabled.value
  })

  const { variant: experimentVariant, loading: isLoadingExperiment } =
    useExperiment(featureId, true, shouldFetchExternalFlag)

  const externalEnabled = computed(() => {
    if (!isExternalFeature(featureId)) return false
    return (
      experimentVariant.value === 'on' ||
      experimentVariant.value === 'treatment'
    )
  })

  const loading = computed(() => {
    return isLoadingExperiment.value
  })

  const enabled = computed(() => {
    const bool = (() => {
      if (localEnabled.value) {
        return true
      } else {
        return externalEnabled.value
      }
    })()
    return bool
  })

  return reactive({
    enabled,
    loading,
  })
}

export function __useFeaturesApi() {
  return reactive({
    featureTuples: computed(
      () =>
        Object.entries(state) as Array<[id: LocalFeatureId, enabled: boolean]>,
    ),
    set(id: LocalFeatureId, bool: boolean) {
      state[id] = bool
      sessionStorage.setItem(
        sessionStorageKey,
        JSON.stringify(Object.entries(state)),
      )
    },
  })
}
